import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_md_checkbox = _resolveComponent("md-checkbox")!

  return (_openBlock(), _createBlock(_component_md_checkbox, {
    modelValue: _ctx.isSelected,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isSelected) = $event)),
    onChange: _ctx.update
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.label), 1)
    ]),
    _: 1
  }, 8, ["modelValue", "onChange"]))
}