
import { TransitionFade } from "@/components/cc/Transition";
import {
  ISnackbarData,
  MdSnackbar,
  MdSnackbarWrapper,
} from "@/components/md/MdSnackbar";
import Sidebar from "@/components/Sidebar.vue";
import GlobalTopNav from "@/components/GlobalTopNav.vue";
import { defaultChartConfig } from "@/utils/GlobalChartConfig";
import router from "@/router";
import { defineComponent } from "vue";
import { useStore } from "@/store/UserStore";

let snackbarTimeoutId: number;
export default defineComponent({
  components: {
    Sidebar,
    GlobalTopNav,
    TransitionFade,
    MdSnackbarWrapper,
    MdSnackbar,
  },
  data: function () {
    return {
      isCollapsed: false,
      userStore: useStore(),
      windowWidth: window.innerWidth,
      showToSmallScreenMessage: false,
    };
  },
  computed: {
    sidenavRail(): boolean {
      // return store.getters[RootStore.Getters.SIDENAV_RAIL];
      return false;
    },
    snackbar(): ISnackbarData | null {
      // return store.getters[RootStore.Getters.GET_SNACKBAR];
      return null;
    },
  },
  watch: {
    snackbar(newValue: ISnackbarData): void {
      if (newValue) {
        if (snackbarTimeoutId) clearTimeout(snackbarTimeoutId);
        snackbarTimeoutId = setTimeout(() => {
          // store.commit(RootStore.Mutations.REMOVE_SNACKBAR);
        }, 3200);
      }
    },
  },
  mounted() {
    defaultChartConfig();

    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
      this.onResize();
    });
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },

  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth < 1024) {
        this.showToSmallScreenMessage = true;
      } else {
        this.showToSmallScreenMessage = false;
      }
    },
  },
});
