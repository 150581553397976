import { defineStore } from "pinia";
import { findAll as findAllEstablishments } from "@/api/EstablishmentApi";
import { findAll as findAllTeams } from "@/api/TeamApi";
import { findAll as findAllEmployees } from "@/api/EmployeeApi";
import { findAll as findAllCorporateStructures } from "@/api/CorporateStructureApi";
import { useStore as useUserStore } from "@/store/UserStore";
import dayjs from "dayjs";
import {
  checkCorporateStrucutrePermissions,
  checkEstablishmentPermissions,
  checkTeamPermissions,
} from "@/utils/PermissionChecker";

interface State {
  loaded: boolean;
  corporateStructures: IFilterItem[];
  establishments: IFilterItem[];
  teams: IFilterItem[];
  employees: IFilterItem[];
  filter: IFilter;
  openFilterOverlayPanel: string | null;
}

interface IFilter {
  corporateStructures: string[];
  establishments: string[];
  teams: string[];
  employees: string[];
  from: string;
  to: string;
  error: string | null;
}

interface IFilterItem {
  id: string;
  label: string;
  active?: boolean;
}

export const useStore = defineStore("filterStore", {
  state: (): State => {
    const now = new Date();
    return {
      loaded: false,
      corporateStructures: [] as IFilterItem[],
      establishments: [] as IFilterItem[],
      teams: [] as IFilterItem[],
      employees: [] as IFilterItem[],
      filter: {
        corporateStructures: [],
        establishments: [],
        teams: [],
        employees: [],
        //init with first day of the year to today
        from: dayjs(new Date(now.getFullYear(), 0, 1)).format(`YYYY-MM-DD`),
        to: dayjs(now).format(`YYYY-MM-DD`),
        error: null,
      },
      openFilterOverlayPanel: null,
    };
  },
  getters: {
    // used for tooltips
    toDate: (state): string => {
      return dayjs(state.filter.to, "YYYY-MM-DD").format("DD.MM.YYYY");
    },
    // shown in the date range filter
    fromDate: (state): string => {
      return state.filter.from;
    },
    // used for api requests
    processedFilter: (state: State): string => {
      // not manipulate the actual filter
      const f = { ...state.filter };

      return JSON.stringify(f);
    },
  },
  actions: {
    async fetchData(): Promise<void> {
      const userStore = useUserStore();
      this.corporateStructures = (await findAllCorporateStructures()).map(
        (o) => {
          return { id: o.internalId, label: o.name };
        }
      );
      if (this.corporateStructures.length > 0) {
        this.filter.corporateStructures.push(this.corporateStructures[0].id);
      } else {
        if (!checkCorporateStrucutrePermissions()) {
          this.corporateStructures.push({
            id: userStore.user.userCorporateStructure,
            label: userStore.user.userCorporateStructureLabel,
          });
          this.filter.corporateStructures.splice(0);
          this.filter.corporateStructures.push(
            userStore.user.userCorporateStructure
          );
        }
      }
      this.establishments = (await findAllEstablishments()).map((o) => {
        return { id: o.internalId, label: o.name };
      });
      if (!checkEstablishmentPermissions()) {
        this.establishments.push({
          id: userStore.user.userEstablishment,
          label: userStore.user.userEstablishmentLabel,
        });
        this.filter.establishments.splice(0);
        this.filter.establishments.push(userStore.user.userEstablishment);
      }
      this.teams = (await findAllTeams()).map((o) => {
        return { id: o.internalId, label: o.name };
      });
      if (!checkTeamPermissions()) {
        if (userStore.user.userTeam) {
          this.teams.push({
            id: userStore.user.userTeam,
            label: userStore.user.userTeamLabel,
          });
          this.filter.teams.splice(0);
          this.filter.teams.push(userStore.user.userTeam);
        }
      }
      this.employees = (await findAllEmployees()).map((o) => {
        return {
          id: o.internalId,
          label: o.name,
          active: o.status === "active",
        };
      });
      if (this.employees.length === 0) {
        this.employees.push({
          id: userStore.user.userEmployee,
          label: userStore.user.userEmployeeLabel,
        });
        this.filter.employees.splice(0);
        this.filter.employees.push(userStore.user.userEmployee);
      } else if (this.employees.length === 1) {
        this.filter.employees.splice(0);
        this.filter.employees.push(this.employees[0].id);
      }
      this.loaded = true;
    },
    updateCorporateStructures(values: string[]): IFilter {
      this.$patch((state) => {
        state.filter.corporateStructures = values.filter(
          (x) => x !== undefined
        );
      });
      return this.updateFilter();
    },
    updateEstablishments(values: string[]): IFilter {
      this.$patch((state) => {
        state.filter.establishments = values.filter((x) => x !== undefined);
      });

      return this.updateFilter();
    },
    updateTeams(values: string[]): IFilter {
      this.$patch((state) => {
        state.filter.teams = values.filter((x) => x !== undefined);
      });

      return this.updateFilter();
    },
    updateEmployees(values: string[]): IFilter {
      this.$patch((state) => {
        state.filter.employees = values.filter((x) => x !== undefined);
      });

      return this.updateFilter();
    },
    updateDate(from: string, to: string): IFilter {
      this.$patch((state) => {
        state.filter.from = from;
      });
      this.$patch((state) => {
        state.filter.to = to;
      });
      return this.updateFilter();
    },
    updateFilter(): IFilter {
      return this.filter;
    },
  },
});
