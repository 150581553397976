import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-680b8a4f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "right group" }
const _hoisted_2 = {
  key: 0,
  class: "left group"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_date_range_filter_button = _resolveComponent("date-range-filter-button")!
  const _component_top_nav_bar_button = _resolveComponent("top-nav-bar-button")!
  const _component_md_filter_button = _resolveComponent("md-filter-button")!
  const _component_error_panel = _resolveComponent("error-panel")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("nav", null, [
      _createElementVNode("div", _hoisted_1, [
        (!_ctx.onlyShowButton)
          ? (_openBlock(), _createBlock(_component_date_range_filter_button, {
              key: 0,
              "leading-icon": `event`,
              "overlay-heading": `Zeitraum wählen`,
              onOnchange: _ctx.updateDate
            }, null, 8, ["onOnchange"]))
          : _createCommentVNode("", true),
        _createVNode(_component_top_nav_bar_button)
      ]),
      (!_ctx.onlyShowButton)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_md_filter_button, {
              disabled: !_ctx.checkReadAllPermissions(),
              icon: "business",
              "btn-header": "Unternehmen wählen",
              "btn-label": "Unternehmen",
              modelValue: _ctx.corporateOptions,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.corporateOptions) = $event)),
              onChange: _ctx.updateCorporateStructures
            }, null, 8, ["disabled", "modelValue", "onChange"]),
            _createVNode(_component_md_filter_button, {
              disabled: !_ctx.checkCorporateStrucutrePermissions(),
              icon: "room",
              "btn-header": "Standort wählen",
              "btn-label": "Standorte",
              modelValue: _ctx.establishmentOptions,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.establishmentOptions) = $event)),
              onChange: _ctx.updateEstablishments
            }, null, 8, ["disabled", "modelValue", "onChange"]),
            _createVNode(_component_md_filter_button, {
              disabled: !_ctx.checkEstablishmentPermissions(),
              icon: "group",
              "btn-header": "Team wählen",
              "btn-label": "Teams",
              modelValue: _ctx.teamOptions,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.teamOptions) = $event)),
              onChange: _ctx.updateTeams
            }, null, 8, ["disabled", "modelValue", "onChange"]),
            _createVNode(_component_md_filter_button, {
              disabled: !_ctx.checkTeamPermissions(),
              icon: "person",
              "btn-header": "Mitarbeiter wählen",
              "btn-label": "Mitarbeiter",
              modelValue: _ctx.employeeOptionsFiltered,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.employeeOptionsFiltered) = $event)),
              filterCheckboxLabel: "Nur aktive Mitarbeiter anzeigen",
              showFilterCheckbox: true,
              onFilterCheckboxValueChanged: _cache[4] || (_cache[4] = (value) => (_ctx.filterCheckboxValue = value)),
              onChange: _ctx.updateEmployees
            }, null, 8, ["disabled", "modelValue", "onChange"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_error_panel)
  ], 64))
}