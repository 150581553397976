import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a9b35d0a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "multi-select__items" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_md_input_field = _resolveComponent("md-input-field")!
  const _component_md_multi_select_item = _resolveComponent("md-multi-select-item")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.searchable)
      ? (_openBlock(), _createBlock(_component_md_input_field, _mergeProps({
          key: 0,
          icon: _ctx.icon,
          variant: "flat",
          modelValue: _ctx.inputText,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputText) = $event))
        }, { required: _ctx.required, disabled: _ctx.disabled }, {
          onFocusout: _ctx.focusOut,
          onSearch: _ctx.clearValues,
          onTrailingIconClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('trailing-icon-click'))),
          label: _ctx.placeholder,
          "trailing-icon": _ctx.trailingIcon
        }), null, 16, ["icon", "modelValue", "onFocusout", "onSearch", "label", "trailing-icon"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.results, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          key: 'md-multi-select-item' + item.value
        }, [
          _createVNode(_component_md_multi_select_item, {
            class: "mb-1",
            value: item,
            label: item.label,
            onItemChange: _ctx.itemChanged
          }, null, 8, ["value", "label", "onItemChange"])
        ]))
      }), 128))
    ]),
    (_ctx.variant === 'filled')
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["bottom-line", [_ctx.hasValue || _ctx.focused ? 'active' : '']])
        }, null, 2))
      : _createCommentVNode("", true)
  ], 64))
}